/* eslint-disable max-len */
/* eslint-disable quotes */
import React from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { Button } from '@material-ui/core';

import SinglePageLayout from '../../components/SinglePageLayout';

export const query = graphql`
  query {
    mobileImage: file(relativePath: { eq: "palsie/cire.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    desktopImage: file(relativePath: { eq: "palsie/cire.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    CardHorizontal1Image: file(relativePath: { eq: "palsie/cire.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, maxHeight: 400, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

const textTop = {
  header: `M.Pals - Beton cire`,
  textWithLink: () => (
    <>
      <p>
        Beton Ciré is een veelgevraagde afwerking vanwege de moderne en strakke
        uitstraling. Beton Ciré is slijtvast en waterdicht en daardoor geschikt
        voor diverse locaties.
      </p>
      <div className='hidden-sm hidden-md hidden-lg'>
        <a href='tel:0629575549'>
          <Button
            role='button'
            variant='contained'
            className='btn heading__button mt-4x '
          >
            <i className='material-icons mr-1x'>phone</i>Krijg advies op maat
          </Button>
        </a>
      </div>
      <AniLink fade to='/contact' className='hidden-xs'>
        <Button
          role='button'
          variant='contained'
          className='btn heading__button mt-4x '
        >
          <i className='material-icons mr-1x'>phone</i>Krijg advies op maat
        </Button>
      </AniLink>
    </>
  ),
};

const textBottom = {
  textWithLink: () => (
    <>
      <AniLink fade to='/speciaal-stukwerk'>
        <Button
          role='button'
          variant='contained'
          className='btn heading__button'
        >
          <i className='material-icons mr-1x'>link</i>Terug naar overzicht
        </Button>
      </AniLink>
    </>
  ),
};

const BetonCire = ({ data, path, location }) => {
  const seo = {
    title: `Beton Cire`,
    description: `M.Pals Stukadoor biedt top kwaliteit Beton Cire aan voor het ultieme industriële gevoel.`,
    pathname: path,
    location,
  };

  const CardHorizontal1 = {
    noSpace: false,
    img: data.CardHorizontal1Image.childImageSharp.fluid,
    imgAlt: 'M.Pals Stukadoors',
    imgTitle: 'M.Pals Stukadoors',
    header: 'Ervaar beton Cire',
    textWithLink: () => (
      <>
        <p>
          Beton Cire is een cementstuc. Het “gepoetst beton” (letterlijke
          betekenis van Beton Cire) geeft een robuuste en strakke uitstraling en
          is zeer sterk, hard en slijtvast. Doordat het van zichzelf al
          waterdicht is en het kan worden aangebracht op bijna iedere
          ondergrond, heeft het vele toepassingsmogelijkheden. Denk daarbij
          bijvoorbeeld aan wanden, vloeren, trappen, badkamers, keukens maar ook
          op objecten als openhaarden, keukenbladen, meubels en badkuipen. Ook
          buiten is het zeer geschikt als afwerking van buitenmuren of terras.
        </p>
      </>
    ),
  };

  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: '(min-width: 970px)',
    },
  ];

  const ribbon = {
    image: sources,
    heading: `Beton Cire`,
    pitchLine: `M.Pals Stukadoor biedt top kwaliteit Beton Cire aan voor het ultieme industriële gevoel.`,
  };

  return (
    <>
      <SinglePageLayout
        seo={seo}
        ribbon={ribbon}
        textTop={textTop}
        CardHorizontal1={CardHorizontal1}
        textBottom={textBottom}
      />
    </>
  );
};

BetonCire.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  path: PropTypes.string,
};

export default BetonCire;
